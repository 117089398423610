<template>
    <div >
     
    </div>
  </template>
  
  <script setup>
  import { ref,defineProps, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { locale } = useI18n();
  const props = defineProps({
    lang:String
  })
  const selectedLanguage = ref(props.lang);
  
  const changeLanguage = () => {
    locale.value = selectedLanguage.value;
  };
  onMounted(()=>{
    changeLanguage()
  })
  </script>
  

<style scoped>

</style>