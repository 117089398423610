// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n'; // Импортируем настройки i18n
import './assets/tailwind.css' // Импорт Tailwind CSS
import router from './router'; // Импорт роутера

const app = createApp(App);

app.use(i18n); // Используем i18n
app.use(router);
app.mount('#app');
