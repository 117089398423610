// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/pages/MainPage.vue';
import AdminPage from '@/components/pages/AdminPage.vue';
const geo = localStorage.getItem('geo'); // Или другой способ получения токена

const routes = [
  {
    path: '/:lang',
    name: 'MainPage',
    component: MainPage,
    props: (route) => ({
      lang: route.params.lang || 'es',
    }),
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage
  },
  {
    path: '/',
    redirect:geo
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
