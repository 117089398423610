<template>
  <div id="app">
    <div v-if="isLoading" class="loading-screen">
      <!-- Можно добавить спиннер или любое другое отображение загрузки -->
      <div class="loader-container">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>


<style>
/* Стили для загрузочного экрана */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Можно изменить фон */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.loader {
  display: flex;
  align-items: flex-end;
}

.loader > div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
</style>
