<template>
  <div class="min-h-screen bg-gray-100 py-8">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold text-gray-900 mb-8">Админ-панель</h1>

      <!-- Список пользователей -->
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h2 class="text-2xl font-semibold text-gray-900">Пользователи</h2>
        </div>
        <ul class="divide-y divide-gray-200">
          <li v-for="user in users" :key="user.chatId" class="px-4 py-4 sm:px-6 hover:bg-gray-50 transition duration-150 ease-in-out">
            <div class="flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div class="mb-2 sm:mb-0">
                <p class="text-sm font-medium text-gray-900">
                  <span class="font-semibold">Chat ID:</span> {{ user.chatId }}
                </p>
                
                <p class="text-sm text-gray-500 mt-1">
                  <span class="font-semibold">Срок доступа:</span> {{ user.expiresAt || "Бессрочный" }}
                </p>
              </div>
              <div class="flex space-x-2 mt-2 sm:mt-0">
                <button @click="setUnlimited(user.chatId)" class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Бессрочный доступ
                </button>
                <button @click="deleteUser(user.chatId)" class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  Удалить
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

    
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      newChatId: '',
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get('/admin/users');
        this.users = response.data;
      } catch (error) {
        console.error('Ошибка загрузки пользователей:', error);
      }
    },
    async generateToken() {
      if (!this.newChatId.trim()) {
        alert('Введите Chat ID');
        return;
      }

      try {
        const response = await axios.post('/admin/generate-token', {
          chatId: this.newChatId,
        });
        alert('Токен создан');
        this.fetchUsers();
        console.log(response)
      } catch (error) {
        console.error('Ошибка генерации токена:', error);
      }
    },
    async setUnlimited(chatId) {
      try {
        await axios.post('/admin/set-unlimited', { chatId });
        alert('Бессрочный доступ установлен');
        this.fetchUsers();
      } catch (error) {
        console.error('Ошибка установки бессрочного доступа:', error);
      }
    },
    async deleteUser(chatId) {
      try {
        await axios.delete('/admin/delete-user', { data: { chatId } });
        alert('Пользователь удален');
        this.fetchUsers();
      } catch (error) {
        console.error('Ошибка удаления пользователя:', error);
      }
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
