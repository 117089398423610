<template>
  <div v-if="unlocked" class="mx-auto">
    <div v-if="forecasts.length" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <div
        style="width: 100%;"
        v-for="(forecast, index) in forecasts"
        :key="index"
        :class="[
          'rounded-lg shadow-md overflow-hidden transition-shadow duration-300 hover:shadow-lg',
          themeClasses.cardBg
        ]"
      >
        <div :class="['px-4 sm:px-6 py-3 sm:py-4', themeClasses.cardHeaderBg]">
          <h3 :class="['font-bold sm:text-lg mb-2', themeClasses.cardHeaderText]">{{ forecast[1] }}</h3>
        </div>
        <div :class="['px-4 sm:px-6 py-3 sm:py-4 card', themeClasses.cardContentBg]">
          <p :class="['text-sm xsm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('forecast') }}:</strong> {{ forecast[2] }}
          </p>
          <p :class="['text-sm xsm:text-base mb-2 date', themeClasses.cardText]">
            <strong></strong> {{ forecast[0] }}
          </p>
          <p :class="['text-sm xsm:text-base mb-2', themeClasses.cardText]">
            <strong>{{ $t('coefficient') }}:</strong> {{ forecast[3] }}
          </p>
          <p :class="['text-sm xsm:text-base', themeClasses.cardText]">
            <strong>{{ $t('league') }}:</strong> {{ forecast[4] }}
          </p>
        </div>
      </div>
    </div>
    <div v-else :class="['text-center py-8', themeClasses.loadingText]">
      <div class="loader-container">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex items-center justify-center min-h-[60vh]">
    <div
      :class="[
        'block-card rounded-lg shadow-xl p-8 max-w-md w-full transform transition-all duration-300 hover:scale-105',
        themeClasses.cardBlock
      ]"
    >
      <div class="flex justify-center mb-6">
        <img src="https://img.icons8.com/?size=100&id=DZbPJ4lsqOdQ&format=png&color=bbbbbb" alt="">
      </div>
      <h2 :class="[themeClasses.blockTitle]">Tab is locked</h2>
      <p class="text-gray-400 text-center mb-6">Make a deposit to gain full access</p>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, defineProps } from 'vue';
import axios from 'axios';



// Получение токена из URL и сохранение в localStorage
const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const geo = params.get('geo')

if (geo){
  localStorage.setItem('geo', geo); // ????????? ????? ??? ???????????? ?????????????

}
if (token) {
  localStorage.setItem('authToken', token); // Сохраняем токен для последующего использования
} else {
  console.error('Токен отсутствует в URL.');
}

const unlocked = ref(true); // Разблокировка вкладок
const forecasts = ref([]); // Данные прогнозов
const errorMessage = ref(null); // Переменная для сообщений об ошибках

const props = defineProps({
  language: {
    type: String,
    default: 'ru',
  },
});

// Функция для загрузки прогнозов
const fetchForecasts = async (lang) => {
  if (!unlocked.value) {
    console.log('Компонент заблокирован. Загрузка прогнозов невозможна.');
    return;
  }

  try {
    const response = await axios.get(`/api/forecasts?lang=${lang}`);
    forecasts.value = response.data;
    errorMessage.value = null; // Успешная загрузка
  } catch (error) {
    errorMessage.value = 'Не удалось загрузить прогнозы. Попробуйте снова.';
    console.error('Ошибка загрузки прогнозов:', error);
  }
};

// Монтаж компонента
onMounted(async () => {
  const storedToken = localStorage.getItem('authToken');

  if (storedToken) {
    try {
      // Проверяем токен на сервере
      const response = await axios.get(`/api/check-access?key=${storedToken}`);
      if (response.data.hasAccess) {
        unlocked.value = true; // Разблокируем компонент
        await fetchForecasts(props.language); // Загружаем прогнозы
      } else {
        console.error('Токен недействителен.');
        unlocked.value = false;
      }
    } catch (error) {
      console.error('Ошибка проверки токена:', error);
      unlocked.value = false;
    }
  } else {
    console.log('Токен отсутствует. Компонент остается заблокированным.');
    unlocked.value = false;
  }

  // Загружаем прогнозы после проверки токена
  if (unlocked.value) {
    await fetchForecasts(props.language);
  }
});

// Слежение за изменением языка
watch(
  () => props.language,
  (newLang) => {
    fetchForecasts(newLang);
  }
);

// Определение тем для каждого языка
const themes = {
  ru: {
    // Светлая тема для русского
    cardBg: 'bg-white',
    cardHeaderBg: 'bg-gray-100',
    cardHeaderText: 'text-gray-800',
    cardContentBg: 'bg-white',
    cardText: 'text-gray-700',
    loadingText: 'text-gray-600',
    cardBlock: 'bg-white',
    blockTitle: 'text-2xl font-bold text-gray-700 text-center mb-4',
  },
  es: {
    // Тёмная тема для испанского
    cardBg: 'bg-gray-800',
    cardHeaderBg: 'bg-gray-700',
    cardHeaderText: 'text-gray-200',
    cardContentBg: 'bg-gray-800',
    cardText: 'text-gray-300',
    loadingText: 'text-gray-400',
    cardBlock: 'bg-gray-900',
    blockTitle: 'text-2xl font-bold text-white text-center mb-4',
  },
  de: {
    // Синяя тема для немецкого
    cardBg: 'bg-blue-100',
    cardHeaderBg: 'bg-blue-300',
    cardHeaderText: 'text-blue-800',
    cardContentBg: 'bg-blue-100',
    cardText: 'text-blue-700',
    loadingText: 'text-blue-600',
    cardBlock: 'bg-blue-100',
    blockTitle: 'text-2xl font-bold text-blue-700 text-center mb-4',
  },
  pt: {
    // Зелёная тема для португальского
    cardBg: 'bg-green-100',
    cardHeaderBg: 'bg-green-300',
    cardHeaderText: 'text-green-800',
    cardContentBg: 'bg-green-100',
    cardText: 'text-green-700',
    loadingText: 'text-green-600',
    cardBlock: 'bg-green-100',
    blockTitle: 'text-2xl font-bold text-green-700 text-center mb-4',
  },
  fr: {
    // Фиолетовая тема для французского
    cardBg: 'bg-purple-100',
    cardHeaderBg: 'bg-purple-300',
    cardHeaderText: 'text-purple-800',
    cardContentBg: 'bg-purple-100',
    cardText: 'text-purple-700',
    loadingText: 'text-purple-600',
    cardBlock: 'bg-purple-100',
    blockTitle: 'text-2xl font-bold text-purple-700 text-center mb-4',
  },
  el: {
    // Оранжевая тема для греческого
    cardBg: 'bg-orange-100',
    cardHeaderBg: 'bg-orange-300',
    cardHeaderText: 'text-orange-800',
    cardContentBg: 'bg-orange-100',
    cardText: 'text-orange-700',
    loadingText: 'text-orange-600',
    cardBlock: 'bg-orange-100',
    blockTitle: 'text-2xl font-bold text-orange-700 text-center mb-4',
  },
};

// Вычисляемое свойство для выбора темы на основе текущего языка
const themeClasses = computed(() => {
  const currentLang = props.language;
  return themes[currentLang] || themes['ru']; // По умолчанию используем русскую тему
});
</script>

<style scoped>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.card {
  position: relative;
}

h3 {
  font-size: 14px;
}

.card p {
  font-size: 12px;
}

.date {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
}

.loader {
  display: flex;
  align-items: flex-end;
}

.loader > div {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-30px);
  }
}
</style>
